@import '../../styles/utils.scss';

.eduContainer{
    .educationList{
        margin: 20px auto;
        border-left: 1px dashed white;
    }
}

.educationCard{
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-top: 10px;

    .eduDates{
        justify-content: space-between;
        font-size: var(--font-size-m);
    }
    
    .school{
        text-align: right;
        
        h1{
            font-size: var(--font-size-l);
            font-weight: var(--font-weight-normal);
            margin: 0 0 10px 0;
        }

        h3{
            margin: 0;
            font-size: var(--font-size-m);
            font-weight: var(--font-weight-light);
        }

        p {
            font-size: var(--font-size-s);
            font-weight: var(--font-weight-light);
        }
    }
}
