@import '../../styles/utils.scss';

.softwareCard{
    padding: 20px;
    margin: 20px auto;
       
    .softwareCardFace{
        .softCardHeader{
            display: grid;
            grid-template-columns: 2fr 1fr;

            .softProjTitle{
                font-size: var(--heading-3-font-size);
                font-weight: var(--font-weight-normal);
                margin: 0 0 20px 0;
            }

            .softProjDates{
                text-align: right;
                font-size: var(--font-size-m);
            }
        }
        
        .softwareCardTags{
            margin-top: 10px;
            flex-wrap: wrap;
            
            .softProjTech{
                margin-right: 10px;
                margin-bottom: 10px;
                padding: 5px 10px;

                border: 1px solid var(--theme-medium-light);                
                border-radius: 1rem;

                font-size: var(--font-size-s);
            }
        }
    }

    .softwareCardExpand{
        margin-top: 20px;

        .softExpDivider{
            margin-top: 10px;
            height: 1px;
            background-color: var(--theme-light);
        }

        .softExpBody{
            margin-top: 20px;
            display: grid;
            grid-template-columns: 3fr 5fr;

            .softwareContent{
                padding-left: 20px;
                
                .softContHeading{
                    font-size: var(--font-size-m);
                    font-weight: var(--font-weight-normal);
                    margin: 0;
                    padding: 0 0 5px 0;
                }

                .softwareDesc{
                    font-weight: var(--font-weight-light);
                    text-align: justify;
                }
                
                .softwareMembers{
                    .softwareMember{
                        display: inline;
                        margin-right: 5px;
                        font-weight: var(--font-weight-lighter);

                        &:hover { text-decoration: underline }
                    }
                }
            }
        }
    }
}

@media (max-width: $small-tab) {
    .softwareCard{
        .softwareCardFace{            
            .softwareCardTags{
                margin-top: 10px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .softwareCardExpand{
            .softExpBody{
                display: flex;
                flex-direction: column;
    
                .softwareContent{
                    padding-left: 0;
                    padding-top: 20px;

                    .softContHeading{
                        padding: 0 0 5px 0;
                        font-size: var(--font-size-l);
                        text-align: center;
                    }
                        
                    .softwareMembers{
                        .memberNames{
                            .softwareMember{
                                display: block;
                                margin-right: 0;
                                margin-bottom: 4px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}