@import './constants.scss';


.removeTextFormat {
    color: inherit;
    text-decoration: none;
}

.fullWidth { width: 100% };

.cursorPointer { cursor: pointer; }

// display: flex styling classNames
.flex { display: flex }

.flexColumn{
    display: flex;
    flex-direction: column;
}

.justifyCenter{ justify-content: center }

.alignCenter { align-items: center }

.section { 
    width: 100%
}

.lightThemeSection {
    background-color: var(--theme-light);
    color: var(--theme-dark);
}

.sectionTitle {
    padding-top: calc($header-height + 20px);
    width: 80%;
    margin: auto;
    text-align: center;
    font-family: 'Fjalla One';
    font-size: var(--heading-1-font-size);
    font-weight: var(--font-weight-normal);
}

.sectionContainer {
    max-width: 960px;
    width: 80%;
    margin: auto;
    padding: 20px 0;
}

.cardBorder {
    border: 1px solid var(--theme-dark);
    overflow: hidden;
}

.cardBorderLight {
    border: 1px solid var(--theme-light);
    overflow: hidden;
}
