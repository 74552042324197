@import '../../styles/constants.scss';

.aboutContainer {
    .greeting {
        height: calc(2 * $header-height);
        background-color: var(--theme-medium-light);
        justify-content: center;
        color: var(--theme-dark);

        h1 {
            font-family: 'Fjalla One';
            font-size: var(--heading-2-font-size);
            font-weight: var(--font-weight-normal);
            text-align: center;
            text-transform: uppercase;
            margin: 0;
        }

    }
}

.banner {
    width: inherit;
    display: block;
    object-fit: cover;
    height: calc(100vh - 2 * $header-height);
}

.introSubTitle {
    font-weight: var(--font-weight-normal);
    font-size: var(--heading-2-font-size);
    font-family: 'Source Sans Pro';
    text-align: center;
}

.aboutContent {
    .intro{
        margin-top: 40px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .introText{
            p {
                padding-right: 40px;
                text-align: justify;
                font-size: var(--font-size-m)
            }
        }

        .introPicture{
            img {
                width: auto;
                object-fit: cover;
                max-height: 500px;
            }
        }
    }
}

@media (max-width: $large-tab) {
    .aboutContent{
        .intro{
            display: flex;
            flex-direction: column-reverse;

            .introText{
                p {
                    padding-right: 0;
                }
            }

            .introPicture{
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 60%;
                }
            }
        }
    }
}

@media(max-width: $small-tab) {}

@media (max-width: $large-phone) {
    .aboutContent{
        .intro{
            .introPicture{
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .introCharContainer{ display: none }
}