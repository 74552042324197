@import '../../styles/utils.scss';

.workContainer{
    .workList{

    }
}

.workCard{
    margin: 20px auto;
    padding: 0 20px 15px;

    .workCardHeader{
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
        margin-bottom: 10px;

        .wcHeaderTitle{
            font-size: var(--heading-2-font-size);
            font-weight: var(--font-weight-lighter);
        }
        
        .workCardLogo{
            justify-content: flex-end;
            padding-top: 20px;
            img{
                width: 300px;
                height: 150px;
                object-fit: contain;
                background-color: white;
            }
        }
    }

    .workCardBody{
        margin: 20px auto;

        .workCardDivider{
            height: 1px;
            background-color: var(--theme-medium-light);
        }

        .workPositions{
            .workPositionCard{
                margin: 10px auto;
                padding:10px;

                .posTitle{
                    font-size: var(--font-size-l);
                    font-weight: var(--font-weight-normal);
                    margin: 0 auto 5px;
                }

                .posSubtitle{
                    font-size: var(--font-size-s);
                    font-weight: var(--font-weight-normal);
                    margin: 0 0 10px;
                }

                .posDuties{
                    font-size: var(--font-size-s);
                    padding-left: 2%
                }
            }
        }
    }
}



@media (max-width: $small-tab) {
    .workCard{
        padding: 0;

        .workCardHeader{
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            margin-bottom: 0px;
    
            .wcHeaderTitle{
                font-size: var(--font-size-l);     
                font-weight: var(--font-weight-normal);
                margin: 15px auto;
            }
            
            .workCardLogo{
                img{
                    width: fit-content;
                    height: 150px;
                    object-fit: contain;
                    background-color: white;
                }
            }
        }

        .workCardBody{ margin: 20px; }
    }
}

@media (max-width: $large-phone) {
    .workCard{ .workCardBody{ margin: 10px; } }
}