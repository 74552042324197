@import '../../styles/utils.scss';

$header-height: 60px;

.headerContainer {
    position: fixed;
    top: 0;
    z-index: 1;
    height: $header-height;
    background-color: var(--theme-dark);
    border-bottom: 1px solid var(--theme-light);

    .headerContent {
        margin: auto 20px;
        justify-content: space-between;

        font-weight: var(--font-weight-light);
        font-size: var(--font-size-m);

        .headerAvatar{ font-size: var(--font-size-l) }

        .headerNavMenu { .navMenOpt { margin: 0px 18px } }

        .headerDrawerBtn, .headerNavDrawer { display: none }
    }
}

@media (max-width: $large-tab) {}

@media (max-width: $small-tab) {
    .headerContainer {   
        .headerContent {
            .headerNavMenu { display: none;}
    
            .headerDrawerBtn { display: block }

            .headerNavDrawer {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 1;

                // Must be here to override the display: none value from large screen
                display: block;
                height: 100vh;

                .navDrawerMenu{                    
                    margin-top: $header-height;
                    height: fit-content;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    background-color: var(--theme-dark);
                    
                    .navDrawerMenOpt {
                        font-size: var(--font-size-l);
                        padding: 10px;
                        border-top: 0.5px solid var(--theme-light);
                    }
                }                
            }
        }
    }
}

@media (max-width: $large-phone) {
    .headerContainer {   
        .headerContent {
            .headerNavDrawer {
                .navDrawerMenu{
                    .navDrawerMenOpt {
                        font-size: var(--font-size-l);
                        padding: 10px;
                        border-top: 0.5px solid var(--theme-light);
                    }
                }                
            }
        }
    }
}

@media (max-width: $small-phone) {
    
}
