@import '../../styles/utils.scss';

.contactContainer{
    min-height: calc(100vh - $header-height);

    .contactGreeting{
        margin: auto;
        text-align: center;
        font-weight: var(--font-weight-lighter);
        font-size: var(--font-size-l);
    }

    .contactContent{
        display: grid;
        grid-template-columns: 4fr 3fr;
    }
}

.contactForm{
    .formItem{
        margin: 20px 0;

        .formItemLabel{
            font-size: var(--font-size-xl);
            font-weight: var(--font-weight-light);
            font-family: 'Source Sans Pro';
            margin: 0 0 20px 0;
        }

        .formItemField{
            width: 100%;
            align-self: flex-start;
            width: 80%;
            height: 40px;
            padding: 0 15px;
            font-size: var(--font-size-s);

            &:focus{
                outline: none;
            }
        }

        .formItemTextArea{
            width: 80%;
            align-self: flex-start;
            height: 160px;
            font-size: var(--font-size-s);
            padding: 15px;
            font-family: 'Source Sans Pro', Courier, monospace;

            &:focus{
                outline: none;
            }
        }

        .errorMessage{
            margin: 5px 0;
            color: #ff4a59;
            font-size: var(--font-size-s);
            min-height: calc(var(--font-size-s) + 4px);
        }
    }

    .formSubmitButton{
        width: 25%;
        height: 40px;
        font-size: var(--font-size-l);
        background-color: var(--theme-medium-dark);
        color: inherit;
        font-family: 'Source Sans Pro';
        font-weight: var(--font-weight-light);
        outline: none;
        border: none;        
    }
}

.documents{
    background-color: var(--theme-medium-light);
    position: relative;
    margin-top: 30px;

    .docsInnerBox{
        margin: 2.5%;
        height: 95%;
        width: 95%;

        border: 1px dashed var(--theme-dark);
        background-color: var(--theme-light);
        color: var(--font-color-dark);

        .docsContainer{
            justify-content: space-around;
            height: 100%;

            .documentItem{
                color: var(--theme-dark);

                .documentTitle{
                    text-align: center;
                    font-size: var(--font-size-l);
                    padding: 10px;
                }

                .documentButton{
                    width: 50%;
                    height: 45px;
                    justify-content: center;
                    align-items: center;

                    border: 1px dashed var(--theme-dark);
                    color: inherit;
                    font-size: var(--font-size-m);
                    text-decoration: none;
                }
            }
        }
    }
}

.contactFooter{
    margin-top: 20px;
    justify-content: space-between;
    
    .footerLabel{
        padding: 10px 0;
    }

    .footerSocials{
        .footerSocial{
            padding: 0 10px;
            font-size: var(--font-size-xl);
        }
    }
}

.notification{
    position: fixed;
    bottom: 15vh;
    left: 0;
    right: 0;
    z-index: 1;

    font-size: var(--font-size-l);
    text-align: center;
    border: 1px solid var(--theme-dark);
    height: 100px;
    font-weight: var(--font-weight-lighter);
}

.showNote{
    animation: 200ms ease-in-out 0s 1 onLoad;
}

.removeNote{
    animation: 200ms ease-in-out 0s 1 onUnload;
    animation-fill-mode: forwards;
}

.successBG { 
    background-color: rgba(0, 128, 0, 90%);
}

.errorBG { 
    background-color: rgba(255, 0, 0, 90%);
}

@keyframes onLoad {
    from { filter: opacity(0)}
    to { filter: opacity(100%)}
}

@keyframes onUnload {
    from { filter: opacity(100%)}
    to { filter: opacity(0)}
}
  

@media (max-width: $small-tab) {
    .contactContainer{
        .contactGreeting{
            font-size: var(--font-size-l);
        }
    
        .contactContent{
            display: flex;
            flex-direction: column;
        }
    }

    .contactForm{
        max-width: 450px;
        margin: 20px auto;

        .formItem{
            margin: 10px 0;
       
            .formItemField{ 
                width: 100%;
                font-size: var(--font-size-m);
            }
            
            .formItemTextArea{
                width: 100%;
                font-size: var(--font-size-m);
            }
        }
    
        .formSubmitButton{ width: 100% }
    }

    .documents{   
        max-width: 450px;
        margin: 20px auto;

        .docsInnerBox{
            margin: 2.5%;
    
            .docsContainer{
                .documentItem{
                    padding: 20px 0;
                }
            }
        }
    }
}

@media (max-width: $large-phone) {
    .contactFooter{
        flex-direction: column-reverse;

        .footerLabel{
            text-align: center;
            width: 100%;
        }
    
        .footerSocials{
            display: flex;
            justify-content: space-around;
            width: 80%;
        }
    }

    .notification{
        height: 40px;
    }
}